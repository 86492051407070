import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import ContactSales from "../components/common/ContactSales"
import SEORevamp from "../components/common/SEO_Revamp"
import ProjectCard from "../components/portfolio-sections/ProjectCard"
import * as styles from "../components/saas-files/portfolio.module.scss"
import MainLayout from "../layouts/MainLayout"
import "../components/home-sections/DownloadGuides.scss"

const PortfolioTemplate = ({ data }) => {
  const sec_1 = data?.strapiPage?.sections[0]
  const sec_2 = data?.strapiPage?.sections[1]
  const sec_3 = data?.strapiPage?.sections[2]
  const sec_4 = data?.strapiPage?.sections[3]
  const sec_5 = data?.strapiPage?.sections[4]
  const projects = data?.portfolio?.sections
  const featured = projects?.filter(({ isActive }) => isActive === true)

  return (
    <MainLayout>
      {/* Hero Section */}
      <section className={styles.hero}>
        <Container>
          <Row className="align-items-center">
            <Col md={6} className="pb-md-5 mb-md-5">
              <h1>{sec_1?.title}</h1>
              <div>{sec_1?.subTitle}</div>
            </Col>
            <Col md={6} className="text-center mt-5 mt-md-0">
              <GatsbyImage
                placeholder="blurred"
                image={getImage(sec_1?.secImages[0]?.localFile)}
                alt={sec_1?.title}
              />
            </Col>
          </Row>
        </Container>
      </section>

      {/* About Project Section */}
      <section className={styles.section}>
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <p className="h5  font-weight-normal mb-0 pb-1">{sec_2?.title}</p>
              <h2
                className="h1 mb-0"
                dangerouslySetInnerHTML={{ __html: sec_2?.subTitle }}
              />
            </Col>
            <Col md={6} className="mt-5 mt-md-0">
              <div
                className="mb-0"
                dangerouslySetInnerHTML={{ __html: sec_2?.subTitle2 }}
              />
            </Col>
          </Row>
        </Container>
      </section>

      {/* Project Goals Section */}
      <section className={` pb-0 ${styles.thirdSec}`}>
        <Container className="text-center pb-5">
          <h2 dangerouslySetInnerHTML={{ __html: sec_3?.title }} />
          <Row className="justify-content-center">
            <Col xs={12} md={9}>
              <div>{sec_3?.subTitle}</div>
            </Col>
          </Row>
        </Container>

        <GatsbyImage
          placeholder="blurred"
          decoding="async"
          loading="lazy"
          image={getImage(sec_3?.secImages[0]?.localFile)}
          alt={sec_1?.title}
        />
      </section>

      {/* Our Approach Section */}
      <section className={styles.section}>
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <h3 className="h5 pb-1 mb-0 font-weight-normal">
                {sec_4?.title}
              </h3>
              <h2
                className="h1 mb-0"
                dangerouslySetInnerHTML={{ __html: sec_4?.subTitle }}
              />
            </Col>
            <Col md={6} className="mb-0">
              <div dangerouslySetInnerHTML={{ __html: sec_4?.subTitle2 }} />
            </Col>
          </Row>
        </Container>
      </section>

      {/* End Result Section */}
      <section className={`${styles.section} ${styles.bgGray}`}>
        <Container className="text-center">
          <h2
            className="mb-3"
            dangerouslySetInnerHTML={{ __html: sec_5?.title }}
          />
          <Row className="justify-content-center">
            <Col xs={12} md={9}>
              <div dangerouslySetInnerHTML={{ __html: sec_5?.subTitle }} />
            </Col>
          </Row>
          {sec_5?.buttons.length !== 0 ? (
            <div className="mt-5">
              <a
                href={sec_5?.buttons[0]?.url}
                target="_blank"
                rel="noopener noreferrer"
                className="btn_black_border"
              >
                {sec_5?.buttons[0]?.title}
              </a>
            </div>
          ) : (
            ""
          )}
        </Container>
      </section>

      {/* Our Approach Section */}
      <section className={styles.section}>
        <Container>
          <Row>
            <Col xs={12}>
              <h2
                className={`main-heading-h2 font-weight-normal my-0 pb-4 text-center`}
              >
                Explore <span className="text-primary">More</span>
              </h2>
            </Col>
            {featured?.map((item, i) => (
              <Col md={6} lg={4} key={i} style={{ padding: 14 }}>
                <ProjectCard item={item} key={i} />
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <ContactSales />
    </MainLayout>
  )
}

export const query = graphql`
  query getPortfolio($id: String) {
    strapiPage(id: { eq: $id }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      seo {
        metaTitle
        metaDescription
      }
    }
    portfolio: strapiPage(slug: { eq: "portfolio" }) {
      sections {
        secName
        title
        subTitle
        isActive
        secImages {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export default PortfolioTemplate

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  const sec_1 = data?.strapiPage?.sections[0]

  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      image={sec_1?.secImages && sec_1?.secImages[0]?.url}
    />
  )
}
