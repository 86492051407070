import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Button from "react-bootstrap/Button"
import * as styles from "./ProjectCard.module.scss"

const ProjectCard = ({ item, explore = false }) => {
  const { title: slug, secName: title, subTitle, secImages } = item
  const image = getImage(secImages && secImages[0]?.localFile)

  return (
    <div className={styles.card}>
      <GatsbyImage
        placeholder="blurred"
        decoding="async"
        loading="lazy"
        image={image}
        alt={title}
        style={{ height: "330px", width: "100%" }}
      />
      <div className={styles.overlay}>
        <h3 className="h4">{title}</h3>
        <div className="mb-3">{subTitle}</div>
        <div>
          <Link to={explore ? `/portfolio/` : `/portfolio/${slug}/`}>
            <Button variant="light" className="btn_white_border">
              {explore ? "Explore More" : "View Project"}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ProjectCard
